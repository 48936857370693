// src/components/TaskForm.js
import React from 'react';
import { Form, Button, Container } from 'react-bootstrap';

const TaskForm = ({ title, setTitle, description, setDescription, createTask }) => {
  return (
    <Container>
      <h2>Create Task</h2>
      <Form>
        <Form.Group controlId="formTaskTitle">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter task title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formTaskDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter task description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" onClick={createTask}>
          Create Task
        </Button>
      </Form>
    </Container>
  );
};

export default TaskForm;
