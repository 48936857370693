// src/App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavigationBar from './components/NavigationBar';
import TaskForm from './components/TaskForm';
import TaskTable from './components/TaskTable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const App = () => {
  const [tasks, setTasks] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axios.get('https://8xdb6se5f7.execute-api.us-east-1.amazonaws.com/dev/tasks');
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const createTask = async () => {
    try {
      const response = await axios.post('https://8xdb6se5f7.execute-api.us-east-1.amazonaws.com/dev/tasks', { title, description });
      setTasks([...tasks, response.data]);
      setTitle('');
      setDescription('');
      toast.success('Task created successfully!');
    } catch (error) {
      console.error('Error creating task:', error);
      toast.error('Error creating task');
    }
  };

  return (
    <>
      <NavigationBar />
      <TaskForm
        title={title}
        setTitle={setTitle}
        description={description}
        setDescription={setDescription}
        createTask={createTask}
      />
      <TaskTable tasks={tasks} />
      <ToastContainer />
    </>
  );
};

export default App;
