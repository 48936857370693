// src/components/TaskTable.js
import React from 'react';
import { Table, Container } from 'react-bootstrap';

const TaskTable = ({ tasks }) => {
  return (
    <Container>
      <h2>Tasks</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task, index) => (
            <tr key={task.taskId}>
              <td>{index + 1}</td>
              <td>{task.title}</td>
              <td>{task.description}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default TaskTable;
